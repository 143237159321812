/* tslint:disable:max-line-length */

import { PLATFORM } from 'aurelia-pal';
import { activationStrategy, RouteConfig } from 'aurelia-router';
import { AureliaConfiguration } from 'aurelia-configuration';

export class Routes {
    public static maps(config: AureliaConfiguration): RouteConfig[] {
        return [
            {
                route: ['', 'home', 'dashboard'],
                name: 'dashboard',
                moduleId: PLATFORM.moduleName('views/dashboard/dashboard'),
                nav: true,
                title: 'Dashboard',
                settings: { icon: 'fal fa-chart-pie', breadcrumb: true, isThreatDetection: true }
            },
            {
                route: 'vessels',
                name: 'vessels',
                moduleId: PLATFORM.moduleName('views/vessels/vessels-index'),
                nav: true,
                title: 'Vessels',
                settings: { icon: 'fal fa-ship', breadcrumb: true, isThreatDetection: true }
            },
            {
                route: 'fleet-health-monitor',
                name: 'fleet-health-monitor',
                moduleId: PLATFORM.moduleName('views/fleet-health-monitor/fleet-health-monitor'),
                nav: true,
                title: 'EDR Fleet health monitor',
                settings: { icon: 'fal fa-laptop-medical', breadcrumb: true, isEdr: true }
            },
            {
                route: 'threats',
                name: 'threats',
                moduleId: PLATFORM.moduleName('views/threats-inbox/threats-inbox'),
                nav: true,
                title: 'Threats',
                activationStrategy: activationStrategy.invokeLifecycle,
                settings: {
                    isIconHtml: true,
                    icon: `
                        <span class="fa-layers fa-fw fa-2x">
                            <i class="fal fa-search"></i>
                            <i class="far fa-bug" data-fa-transform="shrink-9 up-1.8 left-1.5"></i>
                        </span>
                    `,
                    breadcrumb: true,
                    isThreatDetection: true,
                }
            },
            {
                route: 'cases',
                name: 'cases',
                moduleId: PLATFORM.moduleName('views/cases/cases-index'),
                nav: true,
                title: 'Cases',
                settings: { icon: 'fal fa-suitcase', breadcrumb: true, isThreatDetection: true }
            },
            {
                route: 'knowledge-base/:path1?/:path2?/:path3?/:path4?/:path5?',
                name: 'docs',
                moduleId: PLATFORM.moduleName('views/kb/kb'),
                nav: false,
                href: 'knowledge-base',
                title: 'Docs',
                settings: { icon: 'fal fa-books', breadcrumb: false, isThreatDetection: true, isEdr: true }
            },
             {
                route: 'api-docs',
                name: 'api-docs',
                moduleId: PLATFORM.moduleName('views/api-docs/api-docs'),
                nav: false,
                title: 'API Docs',
            },
            {
                route: config.get('vulnerabilityScannerUrl','#'),
                name: 'vulnerability-scanner',
                nav: true,
                redirect: '',
                title: 'Vulnerability Scanner',
                settings: {
                    icon: 'fa-kit fa-light-shield-exclamation-magnifying-glass',
                    breadcrumb: true,
                    target: '_blank',
                    isVulnerabilityScanner: true
                }
            },
            {
                route: 'settings',
                name: 'settings',
                moduleId: PLATFORM.moduleName('views/settings/settings'),
                nav: true,
                title: 'Settings',

                settings: { icon: 'fal fa-gears', breadcrumb: true }
            },
            {
                route: 'auditlogs',
                name: 'auditlogs',
                moduleId: PLATFORM.moduleName('views/audit-logs/audit-logs'),
                nav: false,
                href: 'auditlogs',
                title: 'Audit Logs'
            },
            {
                route: 'switch',
                name: 'switch',
                moduleId: PLATFORM.moduleName('views/switch/switch'),
                nav: false,
                title: 'Switch Company',
                settings: { breadcrumb: false }
            },

            // Authentication
            {
                route: 'login',
                name: 'login',
                moduleId: PLATFORM.moduleName('views/auth/login'),
                nav: false,
                title: 'Login',
                settings: { allowAnonymous: true }
            },
            {
                route: 'login/callback',
                name: 'login-callback',
                moduleId: PLATFORM.moduleName('views/auth/login-callback'),
                nav: false,
                title: 'Login Callback',
                settings: { allowAnonymous: true }
            },
            {
                route: 'error',
                name: 'error',
                title: 'Error',
                moduleId: PLATFORM.moduleName('views/error/error'),
                nav: false,
                settings: { allowAnonymous: true }
            },
            {
                route: ['logout', 'logoff'],
                name: 'logout',
                moduleId: PLATFORM.moduleName('views/auth/logout'),
                nav: false,
                title: 'Logout',
                settings: { allowAnonymous: true }
            },

            // Include the not-found route in the routes so we can also manually navigate to it
            Routes.unknownRoute,
        ] as RouteConfig[];
    }

    public static unknownRoute: RouteConfig = {
        route: 'not-found',
        name: 'unknown',
        title: 'Not Found',
        moduleId: PLATFORM.moduleName('views/not-found/not-found'),
        settings: { allowAnonymous: true }
    };
}
